<template>
  <div class="add-screen">
    <Button
      class="add-screen__add-btn"
      @click="showScreenModal = true"
      theme="secondary"
      icon="Plus"
    >
      {{ $t("pages.screens.addButton") }}
    </Button>
    <Button
      class="add-screen__add-btn ml-2"
      @click="showLicenseModal = true"
      theme="secondary"
      icon="Plus"
    >
      Lizenz ändern
    </Button>

    <Modal
      class="add-screen__modal"
      @close="showLicenseModal = false"
      v-if="showLicenseModal"
      heading="Lizenz ändern"
    >
      <div class="add-screen__modal-content" slot="modal-content">
        <div class="add-screen__current-available">
          Derzeitig verfügbar: {{ available }}
        </div>
        <div class="add-screen__current-licenses">
          Derzeitige Lizenzmenge: {{ oldLicenses }}
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            class="add-screen__form"
            @submit.prevent="handleSubmit(onUpdateScreenLicenses)"
          >
            <ValidationProvider
              class="add-screen__field"
              name="Lizenz"
              rules="required"
              v-slot="{ errors }"
            >
              <label class="add-screen__label" for="Lizenz">
                Lizenzen hinzufügen
              </label>
              <input
                class="add-screen__input"
                type="number"
                v-model="licenses"
                min="0"
              />
              <span class="add-screen__error">{{ errors[0] }}</span>
            </ValidationProvider>

            <div class="add-screen__loading-bar"></div>

            <div class="add-screen__available-summary">
              Neu Verfügbar: {{ Number(available) + Number(licenses) }}
            </div>
            <div class="add-screen__license-summary">
              Neue Lizenzmenge: {{ Number(oldLicenses) + Number(licenses) }}
            </div>
            <Button class="add-screen__create-btn" type="submit">
              Updaten
            </Button>
          </form>
        </ValidationObserver>
      </div>
    </Modal>

    <Modal
      class="add-screen__modal"
      @close="onClose"
      v-if="showScreenModal"
      :heading="$t('pages.screens.screenModal.title')"
    >
      <div class="add-screen__modal-content" slot="modal-content">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            class="add-screen__form"
            @submit.prevent="handleSubmit(onCreateScreen)"
          >
            <ValidationProvider
              class="add-screen__field"
              name="ScreenTitle"
              rules="required"
              v-slot="{ errors }"
            >
              <label class="add-screen__label" for="ScreenTitle">{{
                $t("pages.screens.screenModal.label")
              }}</label>
              <input class="add-screen__input" v-model="name" type="text" />
              <span class="add-screen__error">{{ errors[0] }}</span>
            </ValidationProvider>

            <div class="add-screen__loading-bar"></div>
            <Button class="add-screen__create-btn" type="submit">
              {{ $t("pages.screens.screenModal.createButton") }}
            </Button>
          </form>
        </ValidationObserver>
      </div>
    </Modal>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";

extend("required", required);

export default {
  components: {
    Button,
    Modal,
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    name: "",
    showScreenModal: false,
    showLicenseModal: false,
    oldLicenses: null,
    available: null,
    licenses: 0
  }),
  computed: {
    ...mapGetters("user", {
      user: "getUserProfile"
    })
  },
  mounted() {
    const storedTeam = sessionStorage.getItem("currentTeam");

    if (!this.user?.currentTeam && !storedTeam) {
      this.fetchUserProfile().then(() => {
        this.fetchScreensLicenses(this.user.currentTeam).then(data => {
          this.oldLicenses = data.licenses ? data.licenses : 0;
          this.available = data.available ? data.available : 0;
        });
      });
    } else if (storedTeam) {
      this.fetchScreensLicenses(storedTeam).then(data => {
        this.oldLicenses = data.licenses ? data.licenses : 0;
        this.available = data.available ? data.available : 0;
      });
    } else {
      this.fetchScreensLicenses(this.user.currentTeam).then(data => {
        this.oldLicenses = data.licenses ? data.licenses : 0;
        this.available = data.available ? data.available : 0;
      });
    }
  },
  methods: {
    ...mapActions("screens", [
      "createScreen",
      "fetchScreensLicenses",
      "updateScreensLicenses"
    ]),
    ...mapActions("notifications", ["addNotification"]),
    onCreateScreen() {
      this.createScreen({
        name: this.name,
        orientation: "landscape",
        id: String(new Date().getTime())
      })
        .then(() => {
          this.addNotification({
            title: "screen",
            type: "success",
            autoRemove: true
          });
        })
        .catch(() => {
          this.addNotification({
            title: "error",
            type: "error",
            autoRemove: true
          });
        });
      this.onClose();
    },
    onUpdateScreenLicenses() {
      this.updateScreensLicenses({
        licenses: Number(this.oldLicenses) + Number(this.licenses),
        available: Number(this.available) + Number(this.licenses)
      }).then(data => {
        this.showLicenseModal = false;
        this.licenses = 0;
        this.oldLicenses = data.licenses;
        this.available = data.available;
      });
    },
    onClose() {
      this.newScreen = {};
      this.showScreenModal = false;
    }
  }
};
</script>

<style lang="scss">
.add-screen {
  min-height: 100vh;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__add-btn {
  }

  &__form,
  &__field {
    display: flex;
    flex-direction: column;
  }

  &__field {
    @apply mt-6;
  }

  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-3;
    font-size: 14px;
    line-height: 20px;
  }

  &__label,
  &__error {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  &__error {
    @apply text-ls-red mt-1;
  }

  &__label {
    margin-bottom: 0.25rem;
  }

  &__create-btn {
    @apply mt-4;
  }
}
</style>
